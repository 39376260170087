import React from 'react'
import Navbar from '../inc/Navbar'
import labroomphoto from "../images/labroom photo.jpeg";
import { Link } from "react-router-dom";

function Labroom() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <div className='nctebody'>
                <div className="album py-1">
                    <div className="container ">
                        <div className='row '>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item "><Link style={{ color: "darkBlue" }} to="/" >HOME</Link></li>
                                    <li style={{ color: "darkBlue" }} className="breadcrumb-item active" aria-current="page">LAB ROOM</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

         
            <div className="album p-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="card" >
                                <img src={labroomphoto} className="card-img-top" alt="labroomphoto" />
                                <div className="card-body">
                                    <h4 className="card-title">Lab Room</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />

        </div>
    )
}

export default Labroom