import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Gallerypage from './components/pages/Gallerypage';
import Aboutus from './components/pages/Aboutus';
import Eventpage from './components/pages/Eventpage';
import Labroom from './components/pages/Labroom';
import Library from './components/pages/Library';
import Account from './components/pages/Account';
import Onlineform from './components/pages/Onlineform';
import Affialiate from './components/pages/Affialiate';
import Faculty from './components/pages/Faculty';
import Audit from './components/pages/Audit';


function App() {
  return (
    <Router>
    <div>
      <Routes>
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/contact-us" element={<Contactus/>}/>
      <Route axact path="/Gallerypage" element={<Gallerypage/>}/>
      <Route axact path="/Eventpage" element={<Eventpage/>}/>
      <Route axact path="/about-us" element={<Aboutus/>}/>
      <Route axact path="/Labroom" element={<Labroom/>}/>
      <Route axact path="/library" element={<Library/>}/>
      <Route axact path="/account" element={<Account/>}/>
      <Route axact path="/Online-admission" element={<Onlineform/>}/>
      <Route axact path="/Affialiate" element={<Affialiate/>}/>
      <Route axact path="/Faculty" element={<Faculty/>}/>
      <Route axact path="/audit-report" element={<Audit/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
