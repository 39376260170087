import React, { useState, useEffect }  from 'react';

import { Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import  Navbar  from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";
// import ReactWhatsapp from "react-whatsapp";





function Faculty(props) {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(40);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [gallery, setGallery] = useState([])
    const navigate = useNavigate()

    const galleryCollectionRef = collection(db, "FACULTY");
    useEffect(() => {

        const getGallery = async () => {
            const data = await getDocs(galleryCollectionRef);
            setGallery(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getGallery()
    }, [])
    return (
        
<>



<Navbar/>


<br/><br/>

<br/>
<br/>
<div className='nctebody'>
                <div className="album py-1">
                    <div className="container ">
                        <div className='row '>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item "><Link style={{ color: "darkBlue" }} to="/" >HOME</Link></li>
                                    <li style={{ color: "darkBlue" }} className="breadcrumb-item active" aria-current="page">FACULTY</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


            <ScrollToTop smooth top="100" color="Darkblue" />

<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {gallery.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.name.toLowerCase().includes(searchTerm.toLowerCase()) 
      

      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((olympiad) => { return (
       
            <div className="col-md-4 mt-2" >
                        <div className="card mb-4">
                        <img  className="card-img-top" src={olympiad.img}   alt={ olympiad.name} />
                            <div className="card-body">
                             
                                <h6  style={{color:"Darkblue"}} className="card-text justify"> <b>{ olympiad.name} </b></h6>
                                


                                
                                
                                <div className="d-flex justify-content-between align-items-center">
                              
                                   

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                  
                            </div>
                            </div>

                          
</>

);
}

export default Faculty;