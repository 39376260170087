import React from 'react'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";

import Aboutslider from '../inc/Aboutslider';
import ScrollToTop from "react-scroll-to-top";
import oliveacademy from "../images/gate banner.jpeg";


function Aboutus() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br></br>
            <br />
          
            <ScrollToTop smooth top="100" color="Darkblue"/>
            <div className='nctebody'>
                <div className="album py-1">
                    <div className="container ">
                        <div className='row '>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item "><Link style={{ color: "darkBlue" }} to="/" >HOME</Link></li>
                                    <li style={{ color: "darkBlue" }} className="breadcrumb-item active" aria-current="page">ABOUT US</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="album py-2">
            <div className="container">
                <div className='radius'>
            <img src={oliveacademy} className="d-block w-100" alt="olive academy"/>
            </div>
            </div>
            </div>
        

            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            
                                <Aboutslider />
                                <div className="card-body">
                                    

                                
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          
                            
                                <div className="card-body">
                                    <h4 style={{color:"maroon"}} className="card-title mb-3"><b>About Us</b></h4>
                                    <h6 className="card-text mb-2">Run By GRAM BANGLA EDUCATIONAL CHARITABLE TRUST</h6>
                                    <h5 className="card-text mb-3">ESTD: 2014 |  D.EL.ED Plus B.ED</h5>
                                    <h6 className="card-text"></h6>
                                    <h6 className="card-text">B.ED.-ERCAPP-3340 / D.EL.ED. - ERCAPP-3086</h6>
                                    <br/>
                                    <p>Managing Committee for <b>OLIVE ACADEMY </b> and GRAM BANGLA EDUCATIONAL CHARITABLE TRUST </p>
<h6>1. Joharul SK (President)</h6>
<h6>2. Isha md Chowdhury (Secretary)</h6>
<h6>3. Al Shabir (Treasurer)</h6>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </div>
    )
}

export default Aboutus