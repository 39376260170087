import React from 'react'
import Navbar from '../inc/Navbar'
import AddStudent from '../AddStudent'
import { Link } from "react-router-dom";
import form from "../images/Admission Form.pdf"
import admissionform from "../images/admission-form.jpg";



import ScrollToTop from "react-scroll-to-top";
function Onlineform() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
         <ScrollToTop smooth top="100" color="Darkblue"/>
            <div className='nctebody'>
                <div className="album py-1">
                    <div className="container mt-4">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "black" }} to="/" >HOME</Link></li>
                                <li style={{ color: "black" }} className="breadcrumb-item active" aria-current="page">ADMISSION FORM</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-2">
                <div className="container ">
                    <center>
                        <div className='color'>
                            <h1 className='mb-1'><b>OLIVE ACADEMY</b></h1>
                            <p className='mb-0' style={{ color: "maroon" }}><small>Run By GRAM BANGLA EDUCATIONAL CHARITABLE TRUST

</small></p>
                            <p className='mb-0' style={{ color: "maroon" }}><small>ESTD: 2014 | D.EL.ED Plus B.ED</small></p>
                            <p style={{ color: "maroon" }}><small>B.ED.-ERCAPP-3340 / D.EL.ED. - ERCAPP-3086</small></p>

                        </div>
                        <div className='colortext'>
                        <h2><b>APPLICATION FORM ADMISSION D.EL.ED & B.ED</b></h2>
                        </div>
                    </center>
                </div>
            </div>
            <br />
            <AddStudent/>
            <br />
            {/* <div className="album py-2">
                <div className="container ">
                    <h4>Payment  QR </h4>
                </div>
            </div> */}
       
            <br />
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="card">
                                <img src={admissionform} className="card-img-top" alt="admissionform"/>
                            </div>
                        </div>
                        {/* <div className="col-md-6 mt-3">
                            <div className="card">
                                <img src={form2} className="card-img-top" alt="..."/>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>

            <div className="album py-3">
                <div className="container ">
                    <center>
                        <a href={form} target="_blank" rel="noopener noreferrer">
                            <h5>Offline Download Form <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
  <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>
  <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"/>
</svg></h5></a>
                    </center>
                </div>
            </div>
            <br />
        </div>
    )
}

export default Onlineform