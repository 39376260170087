import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import accountno  from "../images/account.jpeg";


function Account() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="Darkblue"/>
        <div className='nctebody'>
                <div className="album py-1">
                    <div className="container ">
                        <div className='row '>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-4">
                                <li className="breadcrumb-item "><Link style={{ color: "darkBlue" }} to="/" >HOME</Link></li>
                                <li style={{ color: "darkBlue" }} className="breadcrumb-item active" aria-current="page">ACCOUNT NUMBER</li>
                            </ol>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>


            <div className="album py-3">
            <div className="container ">
                <center><h3 style={{color:"darkBlue"}}>College Bank Details</h3></center>
                </div>
                </div>

            <img src={accountno} className="d-block w-100" alt="accountnumbercollge"/>
            <br/>
            <br/>
        
    </div>
  )
}

export default Account