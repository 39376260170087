import React from 'react'
import Navbar from '../inc/Navbar'
import library from "../images/LIBRARY ROOM.jpeg";
import { Link } from "react-router-dom";
import libraryroom from "../images/LIBRARY.jpeg";



function Library() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <div className='nctebody'>
                <div className="album py-1">
                    <div className="container ">
                        <div className='row '>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-4">
                                    <li className="breadcrumb-item "><Link style={{ color: "darkBlue" }} to="/" >HOME</Link></li>
                                    <li style={{ color: "darkBlue" }} className="breadcrumb-item active" aria-current="page">LIBRARY ROOM</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

         
            <div className="album p-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="card" >
                                <img src={library} className="card-img-top" alt="library"/>
                                <div className="card-body">
                                    <h4 className="card-title">Library Room</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="card" >
                                <img src={libraryroom} className="card-img-top" alt="library"/>
                                <div className="card-body">
                                    <h4 className="card-title">Library Room</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />

        </div>
    )
}

export default Library