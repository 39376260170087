import React from 'react'
import Navbar from '../inc/Navbar'
import Gallery from './Gallery'
import { Link } from "react-router-dom";
function Gallerypage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='nctebody'>
                <div className="album py-1">
                    <div className="container ">
                        <div className='row '>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-4">
                                <li className="breadcrumb-item "><Link style={{ color: "darkBlue" }} to="/" >HOME</Link></li>
                                <li style={{ color: "darkBlue" }} className="breadcrumb-item active" aria-current="page">GALLERY PAGE</li>
                            </ol>
                        </nav>
                    </div>
                    </div>
                </div>
            </div>
        <br/>

        <Gallery/>
    </div>
  )
}

export default Gallerypage