
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCSdkX-G6RIMnK8CQNES14vi1QdrIpMiRw",
  authDomain: "olive-academy-7e0c9.firebaseapp.com",
  projectId: "olive-academy-7e0c9",
  storageBucket: "olive-academy-7e0c9.appspot.com",
  messagingSenderId: "1011514284692",
  appId: "1:1011514284692:web:0a3b67525017a9d94db281",
  measurementId: "G-WK6SJ9RZT6"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;