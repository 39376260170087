import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import "./Home.css";
import PresidentJoharulSK from "../images/President Joharul SK.jpg";
import IshaMdChoudhary from "../images/Isha Md Choudhary.jpg";
import principal from "../images/principal.jpeg";
import NoticeBoard from './NoticeBoard';
import Gallery from './Gallery';
import Event from './Event'
import ScrollToTop from "react-scroll-to-top";
import Alshabir from "../images/Al Shabir.jpg";
import chiranjitdas from "../images/chiranjit das.jpg";
import SadrulAlom from "../images/Sadrul.jpg";
import SourabhAgarwal from "../images/Sourabh Agarwal.jpg";


function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="Darkblue" />
      <div className="album py-2">

        <div className="container">
          <h3>olive academy</h3>
        </div>
      </div>
      <Slider />
      <div className='colllege'>
        <marquee>
          <div className="album py-1">
            <div className="container">
              <h5 style={{ color: "white" }} className='mt-2'>Welcome to Olive Academy, D.El.Ed plus B.ed addmission open <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              <span class="visually-hidden">Loading...</span></h5>
            </div>
          </div>
        </marquee>
      </div>
      <br />
      <div className='album py-1'>
        <div className='container'>
          <center>
            <h4 style={{color:"maroon"}}>NCTE NORMS & Affiliation for   INTACK CAPACITY FOR D.EL.ED 2 UNIT(100) & B.ED 2 UNIT(100)</h4>
          </center>
        </div>
      </div>
      <div className='album py-3'>
        <div className='container'>
          <center>
            <h1>Message from  <b>OLIVE ACADEMY Principal</b></h1>

          </center>
          <div className="album py-2">
            <div className="container">
              <div className="row">
                <div className="col-md-4 mt-3">

                  <img src={SourabhAgarwal} className="card-img-top" alt="Sourabh Agarwal"/>
                  <div className="card-body">


                  </div>

                </div>
                <div className="col-md-8 mt-3">


                  <div className="card-body">
                    <h4 className='mb-0'> <b>Dr. Sourabh Agarwal </b> </h4>
                    <p className='textjustify mt-2'>Principal of Dr. Sourabh Agarwal Olive Academy B Ed Section
                    </p>
                    <p className='textjustify'>Our College Address :  Olive Academy

                      Vill. Kanchanpur ( Bagpara) Po. Natunganj Ps. Raghunathganj Dist. Murshidabad, West Bengal, Pin- 742227, WB</p>



                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="album py-2">
            <div className="container">
              <div className="row">
                <div className="col-md-4 mt-3">

                  <img src={principal} className="card-img-top" alt="principal_Mandira Basu Roy" />
                  <div className="card-body">


                  </div>

                </div>
                <div className="col-md-8 mt-3">


                  <div className="card-body">
                    <h4 className='mb-0'> <b>Mandira Basu Roy</b> </h4>
                    <p className='textjustify mt-2'>Principal of D.El.Ed Section Mandira Basu Roy
                    </p>
                    <p className='textjustify'>Our College Address : 

                    Olive Academy Vill. Kanchanpur ( Bagpara) Po. Natunganj Ps. Raghunathganj Dist. Murshidabad, West Bengal, Pin- 742227, WB</p>



                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />



        </div>
      </div>
      <hr />

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mt-3">
              <div class="card border-priamry text-white mb-3" >
                <div class="card-header"><h4 style={{ color: "darkBlue" }}><center><b> IMPORTANT LINK</b></center></h4></div>
                <div class="card-body ">

                  <a href='Online-admission'>
                    <div className="btn-grad14  mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> D.El.Ed plus B.ed addmission open <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span> <h3 className='blink'>New</h3> </div></a >
                  <a href='Eventpage'>
                    <p className="buttonlink me-2 mb-2" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> <small>Event</small> </p></a>

                  <a className="buttonlink me-0 mb-2 me-2" href="Gallerypage"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg><small> Gallery</small></a>
                  <a className="buttonlink me-0 mb-2" href="Labroom" target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg><small> Labroom</small></a>
                  <a className="buttonlink me-2 mb-2" href="account" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg><small> Bank Account Details</small></a>
                  <a className="buttonlink me-2 mb-2" href="about-us" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg><small> About Us</small></a>






                </div>
              </div>
            </div>

            <div className="col-md-5 mt-3">

              <div className="card border-dark mb-3" >

                <div className="card-header"><center>NOTICE BOARD</center></div>


                <div className="card-body text-white">
                  <NoticeBoard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='album py-3'>
        <div className='container'>
          <center>
            <h2>Governing Body of <b>Olive Academy</b></h2>
          </center>
          <div className="album py-2">
            <div className="container">
              <div className="row">
                <div className="col-md-4 mt-3">

                  <div className="card exofficiocard" >
                    <div style={{ color: "darkBlue" }} className="card-header"><center>OLIVE ACADEMY <b> President</b></center></div>
                    <img src={PresidentJoharulSK} className="card-img-top" alt="PresidentJoharulSK" />
                    <div className="card-body">

                      <h2 style={{ color: "darkBlue" }} className='textjustify mt-2 mb-0'> <b>JOHARUL SK</b>
                      </h2>
                      <h5 style={{ color: "grey" }} className='textjustify '> President
                      </h5>
                      <a href='tel: 8918215498'>Mobile:- +91 8918215498</a>
                      {/* <center>
                        <a href="DrDipayan_Tarafdar" className="morebutton">READ MORE</a>
                      </center> */}


                    </div>
                  </div>

                </div>
                <div className="col-md-4 mt-3">
                  <div className="card exofficiocard">
                    <div style={{ color: "darkBlue" }} className="card-header"><center>OLIVE ACADEMY <b> Secretary</b></center></div>
                    <img src={IshaMdChoudhary} className="card-img-top  " alt="IshaMdChoudhary" />
                    <div className="card-body">
                      <h2 style={{ color: "darkBlue" }} className='textjustify mt-2 mb-1'> <b>Isha Mohammad Chowdhury</b>  </h2>

                      <p style={{ color: "grey" }} className='textjustify mb-2'> Governing Body of Olive Academy Secretary
                        Isha Md Chowdhury
                      </p>
                      <a href='tel: 8250906070'>Mobile:- +91 8250906070</a> <br />
                      <a href='tel: 9775809642'>Mobile:- +91 9775809642</a>
                      {/* <center>
                        <a href="DrDipayan_Tarafdar" className="morebutton">READ MORE</a>
                      </center> */}


                    </div>
                  </div>


                </div>
                <div className="col-md-4 mt-3">
                  <div className="card exofficiocard">
                    <div style={{ color: "darkBlue" }} className="card-header"><center>OLIVE ACADEMY <b> Treasurer</b></center></div>
                    <img src={Alshabir} className="card-img-top  " alt="Alshabir" />
                    <div className="card-body">
                      <h2 style={{ color: "darkBlue" }} className='textjustify mt-2 mb-1'> <b>Al Shabir</b>  </h2>

                      <p style={{ color: "grey" }} className='textjustify mb-2'> Governing Body of Olive Academy

                      </p>


                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="card exofficiocard">
                    <div style={{ color: "darkBlue" }} className="card-header"><center>OLIVE ACADEMY </center></div>
                    <img src={chiranjitdas} className="card-img-top  " alt="chiranjitdas" />
                    <div className="card-body">
                      <h2 style={{ color: "darkBlue" }} className='textjustify mt-2 mb-1'> <b>Chiranjit Das </b>  </h2>

                      <p style={{ color: "grey" }} className='textjustify mb-2'> Governing Body of Olive Academy

                      </p>


                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="card exofficiocard">
                    <div style={{ color: "darkBlue" }} className="card-header"><center>OLIVE ACADEMY </center></div>
                    <img src={SadrulAlom} className="card-img-top  " alt="Sadrul Alom" />
                    <div className="card-body">
                      <h2 style={{ color: "darkBlue" }} className='textjustify mt-2 mb-1'> <b>Sadrul Alom</b>  </h2>

                      <p style={{ color: "grey" }} className='textjustify mb-2'> Governing Body of Olive Academy

                      </p>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />



        </div>
      </div>
      <br />
      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-2">
              <h3>Gallery</h3>
              <hr />
            </div>
          </div>
        </div>

      </div>
      <Gallery />
      <br />


      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-2">
              <h3 style={{ color: "grey" }}><b>Event</b></h3>
              <hr />
            </div>
          </div>
        </div>

      </div>
      <Event />
      <div className="album py-2">
        <div className="container">
          <div className="row">
            <iframe className='mb-3' src="https://www.google.com/maps/embed?pb=!4v1724567025793!6m8!1m7!1sJdy9T6jVz_mcR-vmFVOM8w!2m2!1d24.38016614205311!2d88.10191712726476!3f217.27217020103762!4f9.538504129100446!5f1.0900577511272516" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home